import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Alert from '@sm/components/custom/Alert';
import CustomButton from '@sm/components/custom/Button';
import Form from "@sm/core/Form";
import Multiselect from '@sm/components/custom/customMultiselect';
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';

import '@sm/assets/css/userManagement.css';
import UserManagementIcon from '@sm/assets/images/user-management.png';

import localization from '@sm/assets/lang/language';
import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store = require('@sm/reducers/index');

class UserManagement extends Component {
  state = {
    filterOn: false,

    userStatus: [],
    companiesMerchants: [],
    userLevels: [],
    companies: [],
    filteredUsers: [],

    selectedCompany: "",

    controls: new Form({
      merchantIds: [],
      name: "",
      username: "",
      userLevels: [],
      userStatus: []
    }, [{
      name: "merchantIds",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "userLevels",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "userStatus",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "name",
      type: "isString",
      rules: {
        required: false,
      }
    }, {
      name: "username",
      type: "isString",
      rules: {
        required: false,
        min: 1
      }

    }]),

    access: [],

    showAlert: false,
    alertType: "success",
    alertMessage: "",
    usersLoading: false,
    loadingLookups: true,
    searchClicked: false,

    //temporary
    currentLanguage: "en"
  };

  subscribeFunction = null;

  componentDidMount() {
    const { controls } = this.state;
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      crudActions.get('v1/users/management/lookups'),
      crudActions.get(`v1/companies/clients`)
    ]).then(
      (data) => {
        const lookups = data[0];
        const companiesData = data[1];
        if (lookups && companiesData) {
          const userStatus = lookups.userStatus;
          const userLevels = lookups.userLevel.filter(elem => elem.label !== "AGENT");
          const companies = companiesData.map(elem => elem.company);
          companies.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
          const merchants = companiesData.reduce((accumulator, currentElem) => {
            accumulator[currentElem.company.value] = currentElem.clients.map(elem => elem.client);
            return accumulator;
          }, {});

          const updateObject = {
            userStatus: userStatus,
            userLevels: userLevels,
            companies: companies,
            companiesMerchants: merchants,
            loadingLookups: false
          };

          if (storeState.roleId === "MASTER_TECH" || storeState.roleId === "MASTER_ADMIN" || storeState.roleId === "MASTER_USER") {
            companies.unshift({
              value: "0",
              label: "All"
            });

            merchants["0"] = [{
              value: "0",
              label: "All"
            }];
          } else {
            merchants["0"] = [{
              value: "0",
              label: "No items selected."
            }];

            const firstCompany = companies[0].value;
            updateObject.selectedCompany = firstCompany;
          }
        
          if (storeState.roleId && (storeState.roleId === "MASTER_TECH" || storeState.roleId === "MASTER_ADMIN" || 
          storeState.roleId === "MASTER_USER" ||  storeState.roleId === "WL_ADMIN")) {
            updateObject.controls = Object.assign(controls, {
              userLevels: userLevels.map(elem => elem.value),
              userStatus: [userStatus.find(elem => elem.value === "ACTIVE").value]
            });
          }

          this.setState(updateObject, () => {
            if (storeState.roleId && (storeState.roleId === "MASTER_TECH" || storeState.roleId === "MASTER_ADMIN" || 
              storeState.roleId === "MASTER_USER" )) {
              this.onCompanyChange({
                value: "0"
              });
            }
          });
        }
      }
    );
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onValueChange = (event, field) => {
    const { controls, searchClicked } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (searchClicked) {
      controls.isFormValid();
    }

    this.setState({
      controls
    });
  };

  onChangeFilter = () => {
    this.filterUsers();
  };

  filterUsers = () => {
    const { controls, selectedCompany } = this.state;

    const isControlsValid = controls.isFormValid();
    this.setState({
      controls,
      usersLoading: isControlsValid,
      filterOn: true,
      searchClicked: true
    });

    const controlsData = controls.data();
    controlsData.companyIds = [selectedCompany];

    if (isControlsValid) {
      (crudActions.post('v1/users/management/find', controlsData)).then(
        (users) => {
          if (users) {
            this.setState({
              filteredUsers: users,
              usersLoading: false
            });
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              usersLoading: false
            });
          }
        }
      );
    }
  };

  mapSelectedItems = (type) => {
    const field = this.state.controls[type];
    let lookup = this.state[type];
    if (!lookup) {
      lookup = this.state[type];
    }
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  mapMerchants = () => {
    const { selectedCompany, companiesMerchants, controls } = this.state;

    if (!selectedCompany) {
      return [];
    }

    const field = controls.merchantIds;
    const lookup = companiesMerchants[selectedCompany];
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  localizeItems = (items, fieldName) => {
    const { currentLanguage } = this.state;
    const language = localization[currentLanguage];

    const field = language[fieldName];
    return items.map(elem => {
      return { ...elem, label: field[elem.label] };
    });
  };

  userActivation = (activateOrDeactivate, userId) => {
    crudActions.put(`v1/users/management/user/${userId}/${activateOrDeactivate ? "reactivate" : "deactivate"}`).then(
      () => {
        this.setState({
          showAlert: true,
          alertType: "success",
          alertMessage: `User successfully ${activateOrDeactivate ? "activated." : "deactivated."}`
        });
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      }
    );
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: "",
    }, this.state.showAlert && this.state.alertType === "success" ? this.filterUsers : null);
  };

  onCompanyChange = (event) => {
    const { controls, searchClicked } = this.state;
    const company = event.value;

    controls.merchantIds = company === "0" ? ["0"] : [];

    if (searchClicked) {
      controls.isFormValid();
    }

    this.setState({
      selectedCompany: company,
      controls: controls
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  render() {
    const { controls, filterOn, filteredUsers, userLevels, companies, selectedCompany, usersLoading, loadingLookups,
      userStatus, showAlert, alertMessage, alertType, companiesMerchants } = this.state;

    return (
      <Row flexGrow={ 1 } className="module user-management" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ UserManagementIcon } alt="" style={ { marginRight: 10 } } />
                User Management
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                {this.checkPageAccess("USER_EDIT") && <Column horizontal='end' style={ { paddingRight: 15 } }>
                  <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                    <NavLink to={ `/add-user` } className="btn add-deposit-button">
                      CREATE NEW USER
                    </NavLink>
                  </Row>
                </Column>}
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    USER SEARCH
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  {loadingLookups ? <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ { overflow: 'unset' } }>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='space-around' vertical='end' style={ { width: '100%' } }>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> White Label Access </label>
                        <Select
                          id="whiteLabel"
                          name="whiteLabel"
                          value={ selectedCompany || '' }
                          required={ true }
                          clearable={ false }
                          disabled={ companies.length === 1 }
                          onChange={ (value) => this.onCompanyChange(value) }
                          options={ companies }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Merchant </label>
                        <Multiselect
                          isError={ controls.errors.has("merchantIds") }
                          disabled={ !selectedCompany || selectedCompany === "0" }
                          selectedItems={ this.mapMerchants() }
                          items={ selectedCompany ? companiesMerchants[selectedCompany] : [] }
                          type={ "merchantIds" }
                          onChange={ this.onValueChange } />
                      </Column>
                    
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Name </label>
                        <input
                          className={ "form-control " + (controls.errors.has("name") ? "error-field" : "") }
                          value={ controls.name || '' }
                          onChange={ (value) => this.onValueChange(value, 'name') }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Email/Username </label>
                        <input
                          className={ "form-control " + (controls.errors.has("username") ? "error-field" : "") }
                          value={ controls.username || '' }
                          onChange={ (value) => this.onValueChange(value, 'username') }
                        />
                      </Column>
                    </Row>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='center' vertical='center' style={ { width: '100%' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> User Level </label>
                          <Multiselect
                            isError={ controls.errors.has("userLevels") }
                            selectedItems={ this.mapSelectedItems("userLevels", true) }
                            items={ this.localizeItems(userLevels, "roles") }
                            type={ "userLevels" }
                            onChange={ this.onValueChange } />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Status </label>
                          <Multiselect
                            isError={ controls.errors.has("userStatus") }
                            selectedItems={ this.mapSelectedItems("userStatus") }
                            items={ userStatus }
                            type={ "userStatus" }
                            onChange={ this.onValueChange } />
                        </Column>

                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='center' className="input-column fullwidth-button">
                          <CustomButton
                            style={ { width: "100%", outline: usersLoading ? "5px auto -webkit-focus-ring-color" : "0" } }
                            title="Search"
                            type="submit"
                            className="btn defaultBtn"
                            onClick={ () => this.onChangeFilter() }
                          />
                        </Column>
                      </Row>
                    </Row>
                  </div>}
                </Panel.Body>
              </Panel>
              {filterOn && <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    USERS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  {usersLoading ? (
                    <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className="user-management-wrapper">
                        <table className="table table-striped user-management-table">
                          <thead>
                            <tr>
                              <th>
                                Full Name
                              </th>
                              <th>
                                Merchant
                              </th>
                              <th>
                                Email/Username
                              </th>
                              <th>
                                2FA
                              </th>
                              {this.checkPageAccess("USER_VIEW") && <th className="text-center">
                                View
                              </th>}
                              {this.checkPageAccess("USER_EDIT") && <th className="text-center">
                                Edit
                              </th>}
                              {this.checkPageAccess("USER_EDIT") && <th className="text-center">
                                Activation
                              </th>}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              filteredUsers.map((user) => {
                                return <tr key={ user.id }>
                                  <td>
                                    {user.name}
                                  </td>
                                  <td>
                                    {user.merchantName}
                                  </td>
                                  <td>
                                    {user.username}
                                  </td>
                                  <td>
                                    {user.twoFactorEnabled ? "On" : "Off"}
                                  </td>
                                  {this.checkPageAccess("USER_VIEW") && <td>
                                    <NavLink to={ `/view-user/${user.id}` } className="btn view-user">
                                      View
                                    </NavLink>
                                  </td>}
                                  {this.checkPageAccess("USER_EDIT") && <td>
                                    <NavLink to={ `/edit-user/${user.id}` } className="btn edit-user">
                                      Edit
                                    </NavLink>
                                  </td>}
                                  {this.checkPageAccess("USER_EDIT") && <td>
                                    <Button
                                      className={ `btn ${user.active === "ACTIVE" ? "deactivate-user" : "activate-user"}` }
                                      onClick={ () => this.userActivation(user.active === "DEACTIVATED", user.id) }
                                    >
                                      {user.active === "ACTIVE" ? "Deactivate" : "Reactivate"}
                                    </Button>
                                  </td>}
                                </tr>;
                              })
                            }
                          </tbody>
                        </table>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>

    );
  }
}

export default UserManagement;
